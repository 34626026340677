.titulo {
  /* style:{{ backgroundColor: "#FFD700" }} align="center" borderColor="black" */
  background-color: #ffd700;
  border-color: black;
  text-align: center;
  /* border: 1px solid black; */
}
.legenda {
  color: #b22222;
  font-size: 25px;
}


.chartWrapper {
    position: relative;
}

.chartWrapper > canvas {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events:none;
}

.chartAreaWrapper {
    width: 8000px;
    /* overflow-x: scroll; */
}
