.item {
  width: 500px;
  padding: 1em;
  margin: 1em;
  border: 1px solid black;
  display: flex;
  justify-content: space-around;
}

.paginationButton {
  background-color: white;
  border: 1px solid black;
  margin: 5px;
}

.paginationButton:hover {
  background-color: lightgray;
}
